import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {PathConst} from "./utils/PathConst";
import Auth from "./components/Auth";
import AuthFailure from "./components/AuthFailure";
import Dashboard from "./components/Dashboard";
import Config from "./utils/Config";
import OrderComponent from "./components/Order/order-component";

function App() {
    return (
        <Router basename={Config.BASE_PATH}>
            <div className="App">
                <div>
                    <Switch>
                        <Route path={PathConst.AUTH}>
                            <Auth/>
                        </Route>
                        <Route path={PathConst.AUTH_FAILURE}>
                            <AuthFailure/>
                        </Route>
                        <Route path={PathConst.DASHBOARD}>
                            <Dashboard/>
                        </Route>
                        <Route path={PathConst.ORDER}>
                            <OrderComponent/>
                        </Route>
                    </Switch>
                </div>
            </div>
        </Router>
    );
}

export default App;
