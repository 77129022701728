import Config from "./Config";
import { SQSClient } from "@aws-sdk/client-sqs";

const sqsClient = new SQSClient({
    region: "us-east-1",
    credentials: {
        accessKeyId: Config.AWS_ACCESS_KEY_ID,
        secretAccessKey: Config.AWS_SECRET_KEY,
    }
})

export default sqsClient;