import React, {useEffect} from "react";
import {buildDeleteMessageBatchCommand, buildQueueUrl, buildReceiveMessageCommand} from "../../utils/SqsFabricMethods";
import sqsClient from "../../utils/SqsClient";
import {Message, ReceiveMessageCommand} from "@aws-sdk/client-sqs";
import Box from "@mui/material/Box";
import OrderHeader from "./order-header";
import OrderList from "./order-list";
import OrderTotal from "./order-total";
import {DoublePricingOrder} from "../../model/DoublePricingOrder";


const OrderComponent = () => {
    const [order, setOrder] = React.useState<DoublePricingOrder>();
    const [isPolling, setPolling] = React.useState<boolean>(false);


    console.log('data parse', order)

    async function pollMessages(queueUrl: string, command: ReceiveMessageCommand) {
        console.log("Start polling");
        const result = await sqsClient.send(command);
        if (result.Messages != null && result.Messages.length > 0) {
            await deleteMessage(queueUrl, result.Messages);
            let message: Message | null = null;
            result.Messages.forEach(value => {
                if (message == null || message.Attributes!!["SentTimestamp"] < value.Attributes!!["SentTimestamp"]) {
                    message = value;
                }
            });
            const order = JSON.parse(message!!.Body!!);
            console.log("Current order: " + message!!.Body!!);
            setOrder(order);
        }
        console.log("Completed polling");
        pollMessages(queueUrl, command)
    }

    async function deleteMessage(queueUrl: string, messages: Message[]) {
        const command = buildDeleteMessageBatchCommand(queueUrl, messages);
        await sqsClient.send(command);
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const merchantId = searchParams.get("merchant_id")!!;
        const deviceId = searchParams.get("device_id")!!;
        // setOrder(data);
        const queueUrl = buildQueueUrl(merchantId, deviceId);
        console.log('QUEUE URL: ' + queueUrl);
        const command = buildReceiveMessageCommand(queueUrl);
        if (!isPolling) {
            setPolling(true);
            pollMessages(queueUrl, command).then((v: void) => {
                console.log('onfulfilled');
            }, (reason: any) => {
                console.log(reason);
            });
        }
    }, [])
    useEffect(() => {
        const setViewportHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        // Call the function initially
        setViewportHeight();

        // Add event listener for window resize
        window.addEventListener('resize', setViewportHeight);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', setViewportHeight);
        };
    }, []);
    return (
        <>
            <Box bgcolor={'#ffffff'} sx={{display: 'flex', minHeight: '-webkit-fill-available', height: 'calc(var(--vh, 1vh) * 100)', alignItems: 'center', width: '100%', overflow: 'hidden'}}>
                {order != undefined ? <Box className={'order-wrapper-block'}>
                    <OrderHeader orderId={order} />
                    <OrderList order={order} />
                    <OrderTotal orderTotal={order} />
                </Box> : <Box className={'order-wrapper-block order-none'}>Modify the order to view it.</Box>}
            </Box>
        </>

    );
}


export default OrderComponent;
